import { format, parseISO } from 'date-fns';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ICurrencyMatrixExchangeRate,
  IExchangeRateCurrency,
  useGetDistinctCurrencies,
  useGetExchangeRatePeriods,
  useGetMatrixActions,
  useGetMatrixExchangeRatesByPeriodId,
  useUpdateExchangeRateById,
} from 'src/apis/exchangeRateAPI';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import ResponseHandler from 'src/components/ResponseHandler/ResponseHandler';
import {
  Button,
  DataGrid,
  Grid,
  GridRenderCellParams,
  GridRowModel,
  Stack,
} from 'src/components/mui-components';
import { IconButton, Select } from 'src/components/ui-components';
import { getDateLocaleFromUserLanguage } from 'src/utils/date/date';
import styles from './ExchangeRates.module.scss';
import {
  DeletePeriodModal,
  LockPeriodModal,
  NewPeriodModal,
  ResetExchangeRateModal,
  SetupAutomationModal,
  SystemAdminContentWrapper,
} from './components';
import CustomizedCell from './components/CustomizedCell';
import { handleError } from './utils/serverResponseHandler';

type MatrixGridRow = IExchangeRateCurrency & {
  data: ICurrencyMatrixExchangeRate[];
  id: number;
};

const toPeriodWebString = (fromPeriod: Date, toPeriod: Date, locale: Locale) =>
  `${format(fromPeriod, 'dd MMM yyyy', { locale })} - ${format(toPeriod, 'dd MMM yyyy', {
    locale,
  })}`;

const processRow = ({ matrixExchangeRates, distinctCurrencies, period, source }: any) => {
  const row: ICurrencyMatrixExchangeRate[] = [];
  distinctCurrencies.forEach((horizontalCurr: IExchangeRateCurrency) => {
    const target = horizontalCurr.currencyId;
    const matrixData = matrixExchangeRates?.find(
      (obj: ICurrencyMatrixExchangeRate) => obj.lookupKey === `${period}_${target}_${source}`,
    );
    if (matrixData) {
      row.push(matrixData);
    }
  });
  return row;
};

export const SystemAdminExchangeRates = () => {
  // const [editMode, setEditMode] = useState<boolean>(false); // For UX improvement later
  const [isLockModalOpen, setIsLockModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);
  const [isSetupAutomationOpen, setIsSetupAutomationOpen] = useState<boolean>(false);
  const [isResetExchangeRateOpen, setIsResetExchangeRateOpen] = useState<boolean>(false);
  const { t } = useTranslation('systemAdminExchangeRate');
  const { currentLanguage } = useGetCurrentLanguage();
  const {
    exchangeRatePeriods,
    isLoading: isPeriodsLoading,
    isError: isPeriodError,
  } = useGetExchangeRatePeriods();

  const initialPeriod = exchangeRatePeriods?.find(
    (period) =>
      new Date() <= parseISO(period.periodEndDate) &&
      new Date() >= parseISO(period.periodStartDate),
  );

  const currentPeriodId =
    initialPeriod?.intercompanyExchangeRatePeriodId ||
    exchangeRatePeriods?.[0]?.intercompanyExchangeRatePeriodId;

  const [selectedPeriodId, setSelectedPeriodId] = useState<number | undefined>();

  const selectedPeriod = exchangeRatePeriods?.find(
    (e) => e.intercompanyExchangeRatePeriodId === (selectedPeriodId || currentPeriodId),
  );

  const {
    distinctCurrencies,
    isLoading: isCurrenciesLoading,
    isError: isCurrenciesError,
  } = useGetDistinctCurrencies();

  const {
    matrixExchangeRates,
    isLoading: isExchangeRateLoading,
    isError: isExchangeRatesError,
  } = useGetMatrixExchangeRatesByPeriodId(selectedPeriodId || currentPeriodId, !!selectedPeriod);

  const {
    matrixActions,
    // isLoading: isActionsLoading,
    // isError: isActionsError,
  } = useGetMatrixActions();

  const { mutateAsync: updateExchangeRateById, isSuccess: isUpdateExchangeRateSuccess } =
    useUpdateExchangeRateById();

  const handleClickForPeriodChange = (newPeriodId: number) => {
    setSelectedPeriodId(newPeriodId);
  };

  // Sort currencies based on isBasedCurrency = true
  const baseCurrencyIndex = distinctCurrencies.findIndex((currency) => currency.isBaseCurrency);
  if (baseCurrencyIndex !== -1) {
    const baseCurrency = distinctCurrencies.splice(baseCurrencyIndex, 1)[0];
    distinctCurrencies.unshift(baseCurrency);
  }

  const rowsEditableDataGrid: MatrixGridRow[] = distinctCurrencies.map((item, index) => ({
    ...item,
    id: index,
    data:
      selectedPeriodId || currentPeriodId
        ? processRow({
            matrixExchangeRates,
            distinctCurrencies,
            period: selectedPeriodId || currentPeriodId,
            source: item.currencyId,
          })
        : [],
    key: index.toString(),
    currencyAbb: `100 ${item.currencyAbb}`,
  }));

  const processRowUpdate = async (
    newRow: GridRowModel<MatrixGridRow>,
    oldRow: GridRowModel<MatrixGridRow>,
  ) => {
    if (!selectedPeriod?.isPeriodLocked) {
      const updatedCell = newRow.data.find(
        (cell, index) => cell.amount !== oldRow.data[index].amount,
      );

      if (updatedCell && selectedPeriod) {
        try {
          const inverseOfUpdatedCell = matrixExchangeRates.find(
            (exchangeRate) => exchangeRate.lookupKey === updatedCell.inverseLookupKey,
          );
          const response = await updateExchangeRateById(
            {
              exchangeRateId: updatedCell.intercompanyExchangeRateId,
              inverseCurrencyPairExchangeRateId: inverseOfUpdatedCell!.intercompanyExchangeRateId,
              newAmount: updatedCell.amount,
              periodId: selectedPeriod.intercompanyExchangeRatePeriodId,
            },
            {
              onError: (error: any) => {
                handleError(error, t);
              },
            },
          );

          if (response && isUpdateExchangeRateSuccess) {
            return newRow;
          }
        } catch (error) {
          return Promise.reject(oldRow);
        }
      }
    }
    return [];
  };

  const columnsEditableDataGrid = [
    {
      field: 'currencyAbb',
      headerName: '',
      sortable: false,
    },
    ...distinctCurrencies.map((curItem, index) => ({
      field: index.toString(),
      headerName: curItem.currencyAbb,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <CustomizedCell
          row={params.row.id}
          column={curItem.currencyAbb}
          value={params.row.data[index]?.amount}
          editMode={!selectedPeriod?.isPeriodLocked}
          isImmutable={params.row.data[index]?.isImmutable}
          onCellValueChange={(value: string) => {
            const newData = {
              ...params.row,
              data: params.row.data.map((item: ICurrencyMatrixExchangeRate, dataIndex: number) =>
                dataIndex === Number(params.field) ? { ...item, amount: value } : item,
              ),
            };
            processRowUpdate(newData, params.row); // Call processRowUpdate with updated row data
          }}
        />
      ),
      sortable: false,
    })),
  ];

  const actionModalComponent = (actionName: string | undefined) => {
    if (selectedPeriod) {
      switch (actionName) {
        case 'TogglePeriodLock':
          return (
            <Fragment key={actionName}>
              <Button
                className={styles.actionButton}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setIsLockModalOpen(true)}
              >
                {selectedPeriod?.isPeriodLocked ? t('UnlockPeriodButton') : t('LockPeriodButton')}
              </Button>
              <LockPeriodModal
                periodId={selectedPeriod.intercompanyExchangeRatePeriodId}
                currentPeriodIsLocked={selectedPeriod.isPeriodLocked}
                isOpen={isLockModalOpen}
                setIsOpen={setIsLockModalOpen}
              />
            </Fragment>
          );
        case 'DeletePeriod':
          return (
            <Fragment key={actionName}>
              <Button
                className={styles.actionButton}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setIsDeleteModalOpen(true)}
              >
                {t('DeletePeriodButton')}
              </Button>
              <DeletePeriodModal
                periodId={selectedPeriod.intercompanyExchangeRatePeriodId}
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                setSelectedPeriodId={setSelectedPeriodId}
              />
            </Fragment>
          );
        case 'CreateNewManualPeriod':
          return (
            <Fragment key={actionName}>
              <Button
                className={styles.actionButton}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setIsNewModalOpen(true)}
              >
                {t('NewPeriodButton')}
              </Button>
              <NewPeriodModal
                isOpen={isNewModalOpen}
                setIsOpen={setIsNewModalOpen}
                metaData={
                  matrixActions.find((action) => action.actionName === 'CreateNewManualPeriod')
                    ?.metaData
                }
              />
            </Fragment>
          );
        case 'SetupAutomation':
          return (
            <Fragment key={actionName}>
              <Button
                className={styles.actionButton}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setIsSetupAutomationOpen(true)}
              >
                {t('SetupAutomationButton')}
              </Button>
              <SetupAutomationModal
                isOpen={isSetupAutomationOpen}
                setIsOpen={setIsSetupAutomationOpen}
                latestPeriod={exchangeRatePeriods[0]}
                currentMode={
                  (matrixActions.find((action) => action.actionName === 'SetupAutomation')!
                    .metaData![0].defaultValue as 'Automatic' | 'Manual') ?? 'Automatic'
                }
              />
            </Fragment>
          );
        case 'ResetExchangeRates':
          return (
            <Fragment key={actionName}>
              <Button
                className={styles.actionButton}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setIsResetExchangeRateOpen(true)}
              >
                {t('ResetExchangeRatesButton')}
              </Button>
              <ResetExchangeRateModal
                isOpen={isResetExchangeRateOpen}
                setIsOpen={setIsResetExchangeRateOpen}
                periodId={selectedPeriod.intercompanyExchangeRatePeriodId}
              />
            </Fragment>
          );
        default:
          return null;
      }
    }
    return null;
  };

  const dataGridStyle = {
    '.MuiDataGrid-columnHeaders': {
      borderRadius: 0,
      borderBottom: '1px solid rgba(0, 0, 0, .12)',

      '&:not(:has(.MuiDataGrid-columnHeader--filledGroup))': {
        borderTop: '1px solid rgba(0, 0, 0, .12)',
      },
    },

    '.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--filledGroup)': {
      borderTop: 0,
      borderLeft: '1px solid rgba(0, 0, 0, .12)',
      '&:last-child': {
        borderRight: '1px solid rgba(0, 0, 0, .12)',
      },
    },
  };

  return (
    <SystemAdminContentWrapper header={t('PageHeader')} description={t('PageDescription')}>
      <Grid container className={styles.ribbon} rowSpacing={1}>
        <Grid container item xs={12} className={styles.periodSelector}>
          <ResponseHandler
            isLoading={isPeriodsLoading}
            isError={isPeriodError}
            isEmpty={exchangeRatePeriods?.length === 0}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              className={styles.navigationButton}
            >
              {selectedPeriod && selectedPeriod?.previousPeriodId !== 0 && (
                <IconButton
                  iconName="pointLeft"
                  data-automation-id="MatrixNavigatePreviousPeriod"
                  tooltipText="Go to previous period"
                  onClick={() => {
                    handleClickForPeriodChange(selectedPeriod!.previousPeriodId);
                  }}
                />
              )}
              <Select
                name="selectedExchangeRatePeriod"
                label="Exchange rate period"
                hiddenLabel
                inputSize="small"
                value={selectedPeriod ? selectedPeriod.intercompanyExchangeRatePeriodId : 0}
                options={exchangeRatePeriods?.map((period, index) => ({
                  value: period.intercompanyExchangeRatePeriodId,
                  name: toPeriodWebString(
                    parseISO(period.periodStartDate),
                    parseISO(period.periodEndDate),
                    getDateLocaleFromUserLanguage(currentLanguage),
                  ),
                  key: index.toString(),
                }))}
                onChange={(event) => {
                  handleClickForPeriodChange(parseInt(event.target.value, 10));
                }}
              />

              {selectedPeriod && selectedPeriod.nextPeriodId !== 0 && (
                <IconButton
                  iconName="pointRight"
                  data-automation-id="MatrixNavigateNextPeriod"
                  tooltipText="Go to next period"
                  onClick={() => {
                    handleClickForPeriodChange(selectedPeriod.nextPeriodId);
                  }}
                />
              )}
            </Stack>
          </ResponseHandler>
        </Grid>
        <Grid container item xs={12} className={styles.ribbonActions}>
          <Grid item xs={12} md={12}>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" alignItems="center">
              {matrixActions?.map((item) => actionModalComponent(item.actionName))}
            </Stack>
          </Grid>
          {/* For UX improvement later */}
          {/* <MuiGrid item xs={3} md={2}>
            <MuiStack direction="row" spacing={1} alignItems="center" justifyContent="right">
              <MuiSwitch onChange={() => setEditMode(!editMode)} /> <span>Edit Mode</span>
            </MuiStack>
          </MuiGrid> */}
        </Grid>
      </Grid>

      <ResponseHandler
        isLoading={isExchangeRateLoading || isCurrenciesLoading}
        isError={isExchangeRatesError || isCurrenciesError}
        isEmpty={matrixExchangeRates.length === 0}
      >
        <div
          className={styles.exchangeRateTableContent}
          data-automation-id="CurrencyMatrixDataGrid"
        >
          <DataGrid
            columns={columnsEditableDataGrid}
            rows={rowsEditableDataGrid}
            disableColumnMenu
            disableColumnResize
            hideFooter
            sx={dataGridStyle}
          />
        </div>
      </ResponseHandler>
    </SystemAdminContentWrapper>
  );
};

export default SystemAdminExchangeRates;
